<template>
  <div>
    <head-layout  :head-title="$t('cip.dc.zljkcj.title.name')"  :head-btn-options="headBtnOptions" @head-add="addFn" @head-romve="deleteFn">
    </head-layout>
    <!-- <el-container class="new-sino-box"> -->
      <!-- <el-header class="new-sino-header"> -->
        <div class="headers" style="display: flex;">
          <el-input :placeholder="$t('cip.dc.zljkcj.msg.msg1')" v-model="queryList.param1" class="new-sino-input" size="mini" style="width:200px"/>

          <el-button-group style="margin-left: 10px;">
          <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
          <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
        </el-button-group>
        </div>


      <!-- </el-header> -->


      <grid-layout
            ref="gridLayout"
            :data-total="total"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            :page="page"
            @gird-handle-select-click="handleSelectionChange"
            :tableOptions="tableOptions"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :gridRowBtn="gridRowBtn"
            @grid-romve="delRow"
            @grid-edit="handleEdit"
            :searchColumns="searchColumns"
          >
          <template slot="requestMethod" slot-scope="{row}" >
            {{row.requestMode == 1?'get':'post'}}
      </template>

      <template slot="synType" slot-scope="{row}">
              {{ $t('cip.dc.zljkcj.field.increment') }}
            </template>
          </grid-layout>



  </div>
</template>

<script>
import {
  itInfList,
  itInfUp,
  itIndel,
  itInCode,
  datSoSel,
  getAutoCode, tokentDel
} from "@/api/dataAcquisition/index";
import { mapGetters } from "vuex";
import website from '@/config/website';
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-edit",
          remark: 'edit',
          type: "text",
          icon: ""
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "grid-romve",
          remark: 'remove',
          type: "text",
          icon: ""
        },],
        tableOptions: {
        customRowAddBtn: false,
        menuWidth: 150,
        column: [
          {
            label: this.$t('cip.dc.zljkcj.field.InterfaceCode'),
            prop: "infCode",
            cell: true,
            width: 100
          },
          {
            label: this.$t('cip.dc.zljkcj.field.InterfaceName'),
            prop: "infName",
            cell: true,
            width: 200,
            overHidden:true
          },
          {
            label: this.$t('cip.dc.zljkcj.field.InterfaceAddress'),
            prop: "infUrl",
            cell: true,
            width:200,
            overHidden:true
          },
          {
            label: this.$t('cip.dc.zljkcj.field.RequestMethod'),
            prop: "requestMethod",
            cell: true,
            slot:true,

          },
          {
            label: this.$t('cip.dc.zljkcj.field.AccessMode'),
            prop: "synType",
            cell: true,
            slot:true,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.dataType'),
            prop: "dataType",
            cell: true,

          },
          {
            label: this.$t('cip.dc.zljkcj.field.StartTimeField'),
            prop: "startTimeField",
            cell: true,
            width:150,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.EndTimeField'),
            prop: "endTimeField",
            cell: true,
            width:150,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.IncrementalTimestamp'),
            prop: "address",
            cell: true,
            width:150,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.IncreaseStepLengthTime'),
            prop: "stepTime",
            width:200,
            cell: true,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.IncrementalTimeType'),
            prop: "tsFieldType",
            cell: true,
            width:150,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.IncrementalTimeTypeFormat'),
            prop: "formatType",
            cell: true,
            width:200,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.StepLengthdays'),
            prop: "stepSize",
            cell: true,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.ReturnDataItem'),
            prop: "resultData",
            cell: true,
            width:100,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.ReturnStatusCode'),
            prop: "resultCode",
            width:100,
            cell: true,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.ReturnDescription'),
            prop: "resultMsg",
            cell: true,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.PrimarykeyField'),
            prop: "primaryField",
            cell: true,
            width:100,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.SourceSystem'),
            prop: "sourceId",
            cell: true,
            width:200,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.TokenField'),
            prop: "tokenField",
            cell: true,
            width:150,
          },
          {
            label: this.$t('cip.dc.zljkcj.field.SourceDatabaseInformation'),
            prop: "dbInfo",
            cell: true,
            width:200,
          },
        ],
      },
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      tableLoading:false,
      multipleSelection:[],
      tableData: [],
      total:0,
      queryList:{
        query:{
          current:1,
          size:10,
        },
        param1:''
      },
      loading:false,
      headBtnOptions: [{
        label: this.$t("cip.cmn.btn.addBtn"),
        emit: "head-add",
        type: "button",
        icon: ""
      }, {
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-romve",
        type: "button",
        icon: ""
      }],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      itInfList(this.queryList).then(res => {
        this.loading = false;

        this.tableData = res.data.data.dataList
        this.total = res.data.data.page.totalSize
      });
    },
    addFn(){
      this.$router.push({
        path: '/dataAcquisition/zInterfaceAcAdd',
        query: {
          type: 'add',
        }
      })
    },
    handleEdit(item){
      this.$router.push({
        path: '/dataAcquisition/zInterfaceAcAdd',
        query: {
          type: 'edit',
          id:item.id
        }
      })
    },
    delRow(e){
      let ids = []
        ids[0] = e.id
        this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), this.$t("cip.cmn.btn.delBtn"), {
          confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
          cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
          type: 'warning'
        }).then(() => {

          itIndel(ids).then((res)=>{
          this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
          this.onLoad()
        })
        }).catch(() => {

        });

    },
    deleteFn(){
      if(this.multipleSelection.length == 0){
        this.$message.error(this.$t('cip.cmn.msg.warning.tipDetrmineSelectDel'))
        return
      }
      let ids = this.multipleSelection.map((item)=>{
        return item.id
      })
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), this.$t("cip.cmn.btn.delBtn"), {
          confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
          cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: 'warning'
      }).then(() => {
        itIndel(ids).then((res)=>{
          this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    czFn(){
      this.queryList.param1 = '';
      this.onLoad()
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.page.pageSize = val.pageSize
      this.queryList.query.current = 1
      this.queryList.query.size = val.pageSize;
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.page.currentPage = val.currentPage
      this.queryList.query.current = val.currentPage;
      this.onLoad()
    },

  },
  created() {
    this.onLoad();
  },
  mounted() {
  },
};
</script>
<style scoped>
.headers{
  background: #FFFFFF;
  padding: 14px 12px;
}
.headers ::v-deep .el-button{
  height: 28px;
}
</style>
<style>
.el-button+.el-button {
  margin-left: 0px;
}
</style>
